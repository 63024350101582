<template>
  <div>
    <b-breadcrumb style="background-color: #ffffff" :items="items"></b-breadcrumb>
    <b-alert v-model="emailSuccess" variant="success" dismissible>
      {{ emailMsg }}
    </b-alert>
    <h1 class="text-center">{{ $t('orderOptions.yourOrderOptions') }}</h1>
    <b-row class="justify-content-center">
      <b-card-group class="justify-content-center" deck>
        <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
          <b-card
              style="max-width: 40rem; margin-left: auto; margin-right: auto; border-radius: 5px"
          >
            <b-card-title class="ubuntu">{{ $t('orderOptions.loginWithYourEmail') }}</b-card-title>
            <b-card-text class="sansSerif text-left">
              <b-row class="my-5">
                <b-col sm="12">
                  <label :for="Email">Email:</label>
                </b-col>
                <b-col sm="12">
                  <b-form-input v-model="user.eMail" :state="emailState" aria-describedby="email-feedback" id="Email" type="text"></b-form-input>
                  <b-form-invalid-feedback id="email-feedback">
                    {{ $t('pleaseEnterEmail') }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row class="my-5">
                <b-col sm="12">
                  <label :for="Password">Password:</label>
                </b-col>
                <b-col sm="12">
                  <b-form-input v-model="user.password" aria-describedby="password-feedback" :state="passwordState" id="Password" type="password"></b-form-input>
                  <b-form-invalid-feedback id="password-feedback">
                    {{ $t('pleaseEnterPassword') }}
                  </b-form-invalid-feedback>
                </b-col>
                <b-col sm="12">
                  <a href="#" @click="openPasswordModal" v-b-modal.modal-passwort-reset>{{ $t('passwordForgotten') }}</a>
                </b-col>
                <b-col sm="12">
                  <b-alert v-model="error" variant="danger" dismissible>
                    {{ errorMsg }}
                  </b-alert>
                </b-col>
              </b-row>
              <b-row class="my-5">
                <b-col sm="12" class="text-center">
                  <b-button @click="logIn" class="btn sansSerif ml-auto" style="background-color: #0000FF; border-radius: 0!important;
         color: white; max-width: 300px; width: 100%">
                    {{ $t('logInButton') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col class="mt-3 mb-3" cols="12" sm="12" md="6">
          <b-card
              style="max-width: 40rem; margin-left: auto; margin-right: auto"
          >
            <b-card-title class="ubuntu">{{ $t('orderOptions.registerAsAnCustomer') }}</b-card-title>
            <b-card-text class="sansSerif text-left">
              <b-row class="my-5">
                <b-col sm="12" class="text-center">
                  <router-link class="btn sansSerif ml-auto" style="background-color: #0000FF; border-radius: 0!important;
         color: white; max-width: 500px; width: 100%" :to="{ name: 'registration', query: {id: this.$route.query.id, boxName: this.$route.query.boxName, mac: this.$route.query.mac}}">
                    {{ $t('orderOptions.registerNow') }}
                  </router-link>
                </b-col>
              </b-row>
              <b-row class="my-5">
                <b-col sm="12" class="text-center">
                  <router-link class="btn sansSerif ml-auto" style="background-color: #000000; border-radius: 0!important;
         color: white; max-width: 500px; width: 100%" :to="{ name: 'Overview', query: {id: this.$route.query.id, boxName: this.$route.query.boxName, mac: this.$route.query.mac}}">
                    {{ $t('orderOptions.continueAsGuest') }}
                  </router-link>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-card-group>
    </b-row>
    <div>
      <b-row class="my-5" align-h="between">
        <b-col class="justify-content-start align-items-start text-left">
          <router-link class="btn sansSerif mr-auto" style="background-color: #000000; border-radius: 0!important;
         color: white" :to="{ name: this.$route.query.boxName, query: {id: this.$route.query.id, boxName: this.$route.query.boxName}}">
            {{ $t('back') }}
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UserDataService from "../services/UserDataService";

export default {
  name: "orderOptions",
  data() {
    return {
      user: {
        id: null,
        eMail: "",
        password: "",
      },
      error: false,
      errorMsg: '',
      submitted: false,
      emailSuccess: false,
      emailMsg: '',
      items: [
        {
          text: 'Home',
          href: '#/home'
        },
        {
          text: this.$route.query.boxName,
          href:"#/" + this.$route.query.boxName + '?id='+ this.$route.query.id + '&boxName=' + this.$route.query.boxName,
        },
        {
          text: this.$i18n.messages[this.$i18n.locale].orderOptions.orderOptions,
          active: true
        },
      ]
    }
  },
  computed: {
    emailState() {
      return this.user.eMail.length <= 0 && this.submitted ? false : null
    },
    passwordState() {
      return this.user.password.length <= 0 && this.submitted ? false : null
    },
  },
  mounted() {
    console.log(this.$route.params);
    if(this.$route.params.msg){
      console.log("PANOS IN DER HOS MAL 3");
      console.log(this.$route.params.msg);
      this.emailSuccess = true;
      this.emailMsg = this.$route.params.msg;
    }
    console.log("MAC");
    console.log(this.$route.query.mac);
  },
  methods: {
    logIn() {
      console.log("click");
      console.log(this.passwordState);
      console.log(this.emailState);
      this.submitted = true;
      if (this.passwordState != false && this.emailState != false) {
        console.log("TADA");
        let data = {
          email: this.user.eMail,
          password: this.user.password,
        };
        console.log(data);
        UserDataService.logIn(data)
            .then(response => {
              this.user.id = response.data.id;
              console.log(response.data);
              this.$store.commit('SET_ACCESS_TOKEN', {accessToken: response.data.accessToken});
              this.$store.commit('SET_ROLE', {role: response.data.data.role.Name});
              localStorage.role = response.data.data.role.Name;
              localStorage.accessToken = response.data.accessToken;
              let user = {
                id: response.data.data.id,
                name: response.data.data.name,
                zip: response.data.data.zip,
                city: response.data.data.city,
                country: response.data.data.country,
                street: response.data.data.street,
                newsletter: response.data.data.newsletter,
                email: this.user.eMail,
              };
              this.$store.commit('SET_USER', {user: user});
              user = JSON.stringify(user);
              localStorage.user = user;
              this.error = false;
              this.errorMsg = "";
              this.user.eMail = '';
              this.user.password = '';
              this.$router.push({name: 'Overview', query: {id: this.$route.query.id, boxName: this.$route.query.boxName, mac: this.$route.query.mac}});
            })
            .catch(e => {
              console.log(e);
              this.error = true;
              console.log(e.response);
              if(e.response.data.message === 'account does not exist'){
                if(this.$i18n.locale === 'en'){
                  this.errorMsg = e.response.data.message;
                } else {
                  this.errorMsg =  this.errorMsg = 'L\'account non esiste'
                }
              } else if(e.response.data.message === 'Please check if your email and password match'){
                if(this.$i18n.locale === 'en'){
                  this.errorMsg = e.response.data.message;
                } else {
                  this.errorMsg = 'Per favore, controlla che e-mail e password corrispondano';
                }
              } else if(e.response.data.message === 'Please verify your e-mail!'){
                if(this.$i18n.locale === 'en'){
                  this.errorMsg = e.response.data.message;
                } else {
                  this.errorMsg = 'Per favore verifica la tua e-mail!';
                }
              } else {
                this.errorMsg = e.response.data.message;
              }
            });
      }
    },
    openPasswordModal(){
      //this.$refs['login-modal'].hide();
      //this.$refs['password-modal'].show();
      this.$bvModal.show('modal-password-reset');
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');
.ubuntu {
  font-family: 'Ubuntu', sans-serif!important;
}
.sansSerif{
  font-family: 'Open Sans', sans-serif!important;
}
.breadcrumb-item a {
  color: #0000FF;
}
</style>
